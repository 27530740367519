import styled from '@emotion/styled';
import { Alert, Layout } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Content, Header, Footer } = Layout;

const mobileContentPadding = 18; // if change, please change for homepage too.
const tabletContentPadding = 40; // if change, please change for homepage too.

export const StyledLayoutContainer = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  overflow-x: hidden;
`;

export const StyledHeader = styled(Header)`
  background-color: ${props => props.theme.color.white};
  padding: 0 ${mobileContentPadding}px;
  height: 60px;
  line-height: 60px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px;
  }
`;

export const StyledContent = styled(Content)`
  background-color: ${props => props.theme.color.white};
  min-height: 80vh;
  padding: 0 ${mobileContentPadding}px 32px ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 0 ${tabletContentPadding}px 32px ${tabletContentPadding}px;
  }
`;

export const StyledContentBanner = styled(Alert)`
  padding: 8px ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 8px ${tabletContentPadding}px;
  }
`;

export const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  background-color: ${props => props.theme.color.primary};
  padding: 24px ${mobileContentPadding}px;

  @media (min-width: 768px) {
    padding: 24px ${tabletContentPadding}px;
  }
`;

export const StyledFooterSocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledFooterCopyRightText = styled.span`
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const StyledPoweredByText = styled.span`
  color: white;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 24px;
`;

export const StyledFooterPolicyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const StyledFooterLink = styled(Link)`
  color: white;
  width: fit-content;

  &:hover {
    color: white;
  }
`;

export const CartIcon = styled(ShoppingCartOutlined)`
  color: #4f4f4f;
  font-size: 20px;
`;
