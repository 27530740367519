import PropTypes from 'prop-types';

import { CONTACT_NUMBER_REGEX, EMAIL_ADDRESS_REGEX, NRIC_REGEX, PASSWORD_REGEX, PRICE_REGEX, REGEX_NUMBER, USERNAME_REGEX } from 'utils/regex';

import FormItem from 'components/FormItem/FormItem';
import Input from '../Input';

const constructExtraGeneralRules = ({ isContactNumber, isEmail, isNewPassword, isPrice, isUsername, isNRIC, isNumeric }) => {
  let extraGeneralRules = [];

  if (isContactNumber) {
    extraGeneralRules.push({
      pattern: CONTACT_NUMBER_REGEX,
      message: 'Please enter a valid contact number.'
    });
  }
  if (isEmail) {
    extraGeneralRules.push({
      pattern: EMAIL_ADDRESS_REGEX,
      message: 'Please enter a valid email address.'
    });
  }
  if (isNRIC) {
    extraGeneralRules.push({
      pattern: NRIC_REGEX,
      message: 'Please enter a valid NRIC that only contain 12 numbers without hyphen [ - ].'
    });
  }
  if (isNewPassword) {
    extraGeneralRules.push({
      pattern: PASSWORD_REGEX,
      message: `Password must be alphanumeric and at least 1 uppercase letter for a length of 12 - 20 characters.`
    });
  }
  if (isPrice) {
    extraGeneralRules.push({
      pattern: PRICE_REGEX,
      message: 'Please enter a valid number with two decimals only.'
    });
  }
  if (isUsername) {
    extraGeneralRules.push({
      pattern: USERNAME_REGEX,
      message: `Please enter a valid username.`
    });
  }
  if (isNumeric) {
    extraGeneralRules.push({
      pattern: REGEX_NUMBER,
      message: `Please enter only numeric value.`
    });
  }

  return extraGeneralRules;
};

const FormInput = ({
  name,
  label,

  requiredErrorMessage,
  extraRules = [],

  type,
  className,
  placeholder,
  prefix,
  suffix,
  minRows,
  maxRows,
  maxLength,
  size,

  isContactNumber,
  isEmail,
  isNewPassword,
  isPrice,
  isUsername,
  isNRIC,
  isNumeric,

  isUpperCase,
  isLowerCase,
  isDisabled,

  tooltipMessage,

  onChange,
  onPressEnter,

  extraProps
}) => {
  const extraGeneralRules = constructExtraGeneralRules({ isContactNumber, isEmail, isNewPassword, isPrice, isUsername, isNRIC, isNumeric });

  const shouldBeLowerCase = isLowerCase || isEmail;

  return (
    <FormItem
      className={className}
      name={name}
      label={label}
      requiredErrorMessage={requiredErrorMessage}
      extraRules={[...extraRules, ...extraGeneralRules]}
      tooltipMessage={tooltipMessage}
    >
      <Input
        type={type}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        minRows={minRows}
        maxRows={maxRows}
        maxLength={maxLength}
        size={size}
        isDisabled={isDisabled}
        isUpperCase={isUpperCase}
        isLowerCase={shouldBeLowerCase}
        onChange={onChange}
        onPressEnter={onPressEnter}
        extraProps={extraProps}
      />
    </FormItem>
  );
};

FormInput.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,

  requiredErrorMessage: PropTypes.string,
  extraRules: PropTypes.array,

  type: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  size: PropTypes.string,

  isContactNumber: PropTypes.bool,
  isEmail: PropTypes.bool,
  isNewPassword: PropTypes.bool,
  isPrice: PropTypes.bool,
  isUsername: PropTypes.bool,
  isNRIC: PropTypes.bool,
  isNumeric: PropTypes.bool,

  isUpperCase: PropTypes.bool,
  isLowerCase: PropTypes.bool,
  isDisabled: PropTypes.bool,

  tooltipMessage: PropTypes.string,

  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,

  extraProps: PropTypes.object
};

export default FormInput;
