import { useMemo, useState, createContext, useEffect } from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { updateApiHeaderWithStoreId } from 'apis/apiHelper';
import { useGetStoreInfo } from 'apis/store';
import { useIsLoadings } from 'hooks/general';
import { useFetchConstant } from 'hooks/constants';
import { guard } from 'utils/general';
import { get404Route, useNav } from 'utils/routes';

const route404Path = get404Route().path;

const ContextRoot = createContext();

/* ================================================ Local Functions ================================================ */
// ================================================ Color
const useTheme = themeColor => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#FF7212',
        lightPrimary: '#FFCC55',
        lighterPrimary: '#FEF8E5',
        secondary: '#FFBA00',
        lightSecondary: '#FDD8BE',
        tertiary: '#2C278A',
        lightTertiary: '#2196f3',
        lighterTertiary: '#F1F5FC',
        grey: '#797979',
        lightGrey: '#FAFAFA',
        green: '#52C41A',
        red: '#F5222D',
        white: '#FFFFFF',
        black: '#000000',
        borderColor: '#E9E9E9'
      },
      fontFamily: {
        title: "Pacifico, 'cursive'"
      },
      fontWeight: {
        light: 200,
        regular: 400,
        bold: 600,
        bolder: 700
      },
      spacing: {
        xxs: '4px',
        xs: '8px',
        s: '12px',
        regular: '16px',
        md: '32px',
        lg: '40px',
        xl: '48px',
        xxl: '60px',
        xxxl: '104px'
      }
    }),
    []
  );
  const [theme, setTheme] = useState(originalTheme);

  useEffect(() => {
    if (themeColor) {
      setTheme({ ...originalTheme, color: { ...originalTheme.color, primary: themeColor } });
    }
  }, [originalTheme, themeColor]);

  return theme;
};

const useStore = () => {
  const nav = useNav();
  const { data: storeInfo, isLoading: isStoreInfoLoading, isError } = useGetStoreInfo({
    postProcessFunc: storeRes => {
      if (storeRes && storeRes._id) {
        updateApiHeaderWithStoreId(storeRes._id);

        return {
          _id: storeRes._id,
          name: guard(() => storeRes.micrositeSettings.displayName) || storeRes.name,
          displayMessage: storeRes.micrositeSettings.displayMessage,
          currencySymbol: storeRes.currency && storeRes.currency.symbol,
          customDomainLogoUrl: guard(() => storeRes.micrositeSettings.customLogo.original.url),
          themeColor: storeRes.micrositeSettings.themeColor,
          logoUrl:
            guard(() => storeRes.micrositeSettings.logo.original.url) || guard(() => storeRes.logoV2.original.url) || guard(() => storeRes.logo.url),
          bannerUrl: guard(() => storeRes.micrositeSettings.bannerV2.original.url) || guard(() => storeRes.micrositeSettings.banner.url),
          descriptions: storeRes.micrositeSettings.descriptions,
          socialMediaFbPageId: storeRes.socialMediaFbPageId,
          socialMediaFb: guard(() => storeRes.micrositeSettings.socialMediaLink.fb),
          socialMediaIg: guard(() => storeRes.micrositeSettings.socialMediaLink.ig),
          socialMediaTg: guard(() => storeRes.micrositeSettings.socialMediaLink.tg),
          socialMediaWa: guard(() => storeRes.micrositeSettings.socialMediaLink.wa),
          socialMediaMap: guard(() => storeRes.micrositeSettings.socialMediaLink.map),
          socialMediaTt: guard(() => storeRes.micrositeSettings.socialMediaLink.tt),
          termsOfService: storeRes.micrositeSettings.termsOfService,
          privacyPolicy: storeRes.micrositeSettings.privacyPolicy,
          refundPolicy: storeRes.micrositeSettings.refundPolicy,
          categories: storeRes.micrositeSettings.categories,
          contents: storeRes.micrositeSettings.contents,
          subdomainName: storeRes.subdomainName,
          live: storeRes.live,
          isLive: storeRes.isLive,
          isMicrositeProLayoutEnabled: storeRes.subscriptionConfig.isMicrositeProLayoutEnabled,
          layoutView: storeRes.micrositeSettings.layoutView,
          productView: storeRes.micrositeSettings.productView,
          isAllowMsLiveVideo: storeRes.micrositeSettings.isAllowMsLiveVideo,
          isAllowShowAllCategory: storeRes.micrositeSettings.isAllowShowAllCategory
        };
      }

      return storeRes;
    }
  });

  useEffect(() => {
    if (isError) {
      nav(route404Path);
    }
  }, [isError, nav]);

  return { isLoading: isStoreInfoLoading, storeInfo };
};

/* ================================================ Main Component ================================================ */
export const ContextRootProvider = ({ children }) => {
  const { isLoading: isStoreLoading, storeInfo } = useStore();
  const { data: layoutViews, isLoading: isLayoutViewsLoading } = useFetchConstant('micrositeLayoutViews');
  const { data: productViews, isLoading: isProductViewsLoading } = useFetchConstant('micrositeProductViews');

  const isProLayoutView = useMemo(() => guard(() => storeInfo.isMicrositeProLayoutEnabled && storeInfo.layoutView === layoutViews.PRO.code, false), [
    storeInfo,
    layoutViews
  ]);
  const isCompactProductView = useMemo(() => guard(() => storeInfo.productView === productViews.COMPACT.code, false), [storeInfo, productViews]);
  const theme = useTheme(isProLayoutView ? storeInfo.themeColor : undefined);

  const { isLoading } = useIsLoadings([isStoreLoading, isLayoutViewsLoading, isProductViewsLoading]);

  return (
    <ContextRoot.Provider value={{ propsContextRoot: { isLoading, storeInfo, isProLayoutView, isCompactProductView } }}>
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </ContextRoot.Provider>
  );
};

export const withContextRoot = Component => {
  const ContextRootComponent = props => (
    <ContextRoot.Consumer>{({ propsContextRoot }) => <Component propsContextRoot={propsContextRoot} {...props} />}</ContextRoot.Consumer>
  );
  return ContextRootComponent;
};
