import { useState, useMemo, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  CheckOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  DoubleRightOutlined,
  MinusOutlined,
  PlusOutlined,
  SmileOutlined
} from '@ant-design/icons';
import { Alert, Button, Col, Descriptions, Divider, Empty, Form, List, message, Modal, Popconfirm, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { withContextCart } from 'contexts/ContextCart/ContextCart';

import OutOfStockLabel from 'components/_local/OutOfStockLabel/OutOfStockLabel';
import PriceDisplay from 'components/_local/PriceDisplay/PriceDisplay';
import FormInput from 'components/Input/FormInput/FormInput';
import FormContact from 'components/Contact/FormContact/FormContact';
import Selection from 'components/Selection/Selection';

import ImagePlaceholder from 'images/no-image-placeholder.jpg';
import EmptyCartImagePlaceholder from 'images/empty-shopping-cart-placeholder.png';

import { getCustomerPendingOrdersWithContact } from 'apis/cart';
import { useScrollToTop } from 'hooks/utils';
import { getHomeRoute, constructCheckoutURL, useNav } from 'utils/routes';
import { generateQtyLimit } from 'utils/cart';
import { errorHandler } from 'utils/general';
import { logError } from 'utils/logging';

import {
  ContentContainer,
  ContentBasicInfoContainer,
  ContentSummaryInfoContainer,
  PhotoCol,
  StyledImagesPreview,
  OriPricingInfoLabel,
  PricingInfoLabel,
  PricingActionsContainer,
  ProductInfoDesc,
  ProductInfoDescMobile,
  ProductInfoDescShowButton,
  CartProductItemRow
} from './Cart.styles';

const homeRoute = getHomeRoute();

const { useForm } = Form;
const {
  Item: ListItem,
  Item: { Meta: ListItemMeta }
} = List;

const ProductInfo = ({ t, label, desc }) => {
  const [showDesc, setShowDesc] = useState(false);

  return (
    <>
      <h3 style={{ color: '#000000D9' }}>{label}</h3>
      {desc && (
        <>
          {showDesc && <ProductInfoDescMobile>{desc}</ProductInfoDescMobile>}
          <ProductInfoDescShowButton
            type="text"
            onClick={() => setShowDesc(!showDesc)}
            icon={!showDesc ? <CaretDownOutlined /> : <CaretUpOutlined />}
          >
            {!showDesc ? t('pageCart:mobile-show-more-details') : t('pageCart:mobile-show-less-details')}
          </ProductInfoDescShowButton>
        </>
      )}
      {desc && <ProductInfoDesc>{desc}</ProductInfoDesc>}
    </>
  );
};

const CartProductItem = ({
  form,
  formInputName,
  priceAmt,
  oriPrice,
  limitQty = 0,
  onRemoveItem,
  onUpdateQuantity,
  hasStock,
  hasFeedback,
  feedbackMessage
}) => {
  const { t } = useTranslation(['pageCart']);
  const handleOnClickAddQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName[0]][formInputName[1]];
    const newValue = !currentValue ? 1 : Number(currentValue) + 1;

    form.setFieldsValue({ [formInputName[0]]: { [formInputName[1]]: newValue } });
    form.validateFields([formInputName]);
    onUpdateQuantity(newValue, limitQty);
  };

  const handleOnClickDeductQty = () => {
    const formValue = form.getFieldsValue([formInputName]);
    const currentValue = formValue[formInputName[0]][formInputName[1]];
    const newValue = currentValue > 1 ? Number(currentValue) - 1 : undefined;

    if (!currentValue) {
      return;
    } else {
      form.setFieldsValue({ [formInputName[0]]: { [formInputName[1]]: newValue } });
      form.validateFields([formInputName]);

      onUpdateQuantity(newValue, limitQty);
    }
  };

  return (
    <CartProductItemRow justify="space-between">
      <Col>
        {oriPrice && <OriPricingInfoLabel amount={oriPrice} />}
        <PricingInfoLabel amount={priceAmt} hasOriPrice={!!oriPrice} />
      </Col>
      <Col span={24} sm={12} md={14} xl={12}>
        <PricingActionsContainer>
          <Col style={{ marginRight: '4px' }}>
            <Popconfirm
              title={t('pageCart:confirm-to-delete-message')}
              onConfirm={onRemoveItem}
              okText={t('pageCart:confirm-to-delete-yes')}
              cancelText={t('pageCart:confirm-to-delete-no')}
            >
              <Button icon={<DeleteOutlined />} style={{ marginBottom: 24 }} />
            </Popconfirm>
          </Col>
          <Col span={19} xs={18} md={16} xl={14}>
            {hasStock ? (
              <div style={{ display: 'flex' }}>
                <Button icon={<MinusOutlined />} style={{ width: '60px' }} onClick={handleOnClickDeductQty} />
                <FormInput
                  name={formInputName}
                  placeholder="e.g.: 2"
                  isNumeric
                  onChange={value => onUpdateQuantity(value, limitQty)}
                  extraRules={[
                    {
                      validator: (_, value) => {
                        if (!hasFeedback && !!value && Number(value) > limitQty) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      },
                      message: `Stock left ${limitQty} for this item.`
                    },
                    {
                      validator: (_, value) => {
                        if (!value || (!!value && Number(value) < 1)) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      },
                      message: `Must add at least ONE (1) quantity.`
                    },
                    {
                      validator: (_, value) => {
                        if (hasFeedback) {
                          return Promise.reject();
                        } else {
                          return Promise.resolve();
                        }
                      },
                      message: feedbackMessage
                    }
                  ]}
                />
                <Button icon={<PlusOutlined />} style={{ width: '60px' }} onClick={handleOnClickAddQty} />
              </div>
            ) : (
              <OutOfStockLabel showError={hasFeedback} />
            )}
          </Col>
        </PricingActionsContainer>
      </Col>
    </CartProductItemRow>
  );
};

const showConfirmModalCheckout = ({ t, combineOrderNumber, onOk, onCancel }) => {
  return Modal.confirm({
    title: t('pageCart:modal-confirm-title'),
    icon: <SmileOutlined />,
    content: (
      <>
        {combineOrderNumber && <p>{t('pageCart:modal-confirm-combine-order-message', { orderNumber: combineOrderNumber })}</p>}
        <p>
          {/* Extra spacing to cater for EN trans, CN looks weird but rignt now we nede to workaround this. */}
          {t('pageCart:modal-confirm-message-1')} <strong>{t('pageCart:modal-confirm-message-highlight-2')}</strong>{' '}
          {t('pageCart:modal-confirm-message-3')}
        </p>
      </>
    ),
    okText: t('pageCart:modal-confirm-ok'),
    cancelText: t('pageCart:modal-confirm-cancel'),
    onOk,
    onCancel
  });
};

const useCheckout = ({ t, form, sourceOrder, updateProductItemsValidity, updateCacheCustomer, checkoutCart } = {}) => {
  const [hasInitiateCheckout, setHasInitiateCheckout] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();

  const [isLoadingCustomerPendingOrders, setIsLoadingCustomerPendingOrders] = useState(false);
  const [customerPendingOrders, setCustomerPendingOrders] = useState([]);

  const combineOrder = useMemo(() => sourceOrder || selectedOrder, [sourceOrder, selectedOrder]);

  const isLoading = isValidating || isLoadingCustomerPendingOrders || isCreatingOrder;
  const shouldAskForCombineOrder =
    hasInitiateCheckout && !isValidating && !sourceOrder && !isLoadingCustomerPendingOrders && customerPendingOrders.length > 0;

  const redirectToCheckoutPage = useCallback(
    ({ orderNumber, accessCode }) => {
      const redirectUrl = constructCheckoutURL(orderNumber, accessCode);
      Modal.success({
        title: t('pageCart:success-checkout-message'),
        content: t('pageCart:success-redirect-message'),
        okText: t('pageCart:success-redirect-ok-button'),
        onOk: () => {
          window.open(redirectUrl, '_self');
        }
      });
      setTimeout(() => {
        window.open(redirectUrl, '_self');
      }, 5000);
    },
    [t]
  );

  const resetState = useCallback(() => {
    setHasInitiateCheckout(false);
    setIsValidating(false);
    setIsLoadingCustomerPendingOrders(false);
    setIsCreatingOrder(false);
    setCustomerPendingOrders([]);
    setSelectedOrder();
  }, []);

  const showModalFinalConfirm = useCallback(
    orderToCombine => {
      const { name, email, contact } = form.getFieldsValue();

      showConfirmModalCheckout({
        t,
        combineOrderNumber: orderToCombine && orderToCombine.orderNumber,
        onOk: () => {
          setIsCreatingOrder(true);
          setHasInitiateCheckout(false);
          const customerInfo = { name, email, contact };

          checkoutCart(customerInfo, orderToCombine)
            .then(resData => {
              updateCacheCustomer(resData.customer);
              resetState();
              redirectToCheckoutPage(resData);
            })
            .catch(errRes => {
              resetState();
              errorHandler(errRes, { prefixErrorMessage: t('pageCart:api-error-prefix') });
              window.scrollTo({
                top: 80,
                behavior: 'smooth'
              });
            });
        },
        onCancel: () => {
          resetState();
        }
      });
    },
    [t, form, updateCacheCustomer, checkoutCart, redirectToCheckoutPage, resetState]
  );

  const handleOnClickProceedToCheckout = useCallback(
    async values => {
      setHasInitiateCheckout(true);
      setIsValidating(true);

      try {
        await updateProductItemsValidity();
        setIsValidating(false);
        if (combineOrder) {
          showModalFinalConfirm(combineOrder);
        } else {
          setIsLoadingCustomerPendingOrders(true);
          getCustomerPendingOrdersWithContact(values.contact)
            .then(resPendingOrders => {
              if (resPendingOrders && resPendingOrders.length === 0) {
                showModalFinalConfirm();
              }
              setCustomerPendingOrders(resPendingOrders);
            })
            .catch(ex => {
              logError('Something went wrong when fetching customer pending orders', ex);
              showModalFinalConfirm();
            })
            .finally(() => {
              setIsLoadingCustomerPendingOrders(false);
            });
        }
      } catch (ex) {
        errorHandler(ex);
        form.validateFields();
        window.scrollTo({
          top: 80,
          behavior: 'smooth'
        });
        resetState();
      }
    },
    [form, combineOrder, updateProductItemsValidity, showModalFinalConfirm, resetState]
  );

  const handleOnCloseModal = useCallback(() => {
    resetState();
  }, [resetState]);

  const handleOnSelectCombineOrder = useCallback(
    (orderNumber, accessCode) => {
      const orderToCombine = {
        orderNumber: orderNumber,
        accessCode: accessCode
      };
      setSelectedOrder(orderToCombine);
      showModalFinalConfirm(orderToCombine);
    },
    [showModalFinalConfirm]
  );

  const handleOnSelectSeperateOrder = useCallback(() => {
    showModalFinalConfirm();
  }, [showModalFinalConfirm]);

  return {
    hasInitiateCheckout,
    isLoading,
    shouldAskForCombineOrder,

    customerPendingOrders,
    handleOnCloseModal,
    handleOnClickProceedToCheckout,
    handleOnSelectCombineOrder,
    handleOnSelectSeperateOrder
  };
};

const ModalSelectPendingOrders = ({ t, isShowModal, pendingOrders = [], onCloseModal, onSeperateOrder, onCombineOrder }) => {
  const [combineOrderStr, setCombineOrderStr] = useState(); // STOR-OR-1234:o_ABC123

  const formatOrderToCombineOrderStr = order => {
    return `${order.orderNumber}:${order.accessCode}`;
  };

  useEffect(() => {
    if (pendingOrders && pendingOrders.length > 0 && !combineOrderStr) {
      setCombineOrderStr(formatOrderToCombineOrderStr(pendingOrders[0]));
    }
  }, [pendingOrders, combineOrderStr]);

  return (
    <Modal
      title={t('pageCart:modal-select-pending-orders-title')}
      open={isShowModal}
      destroyOnClose
      onCancel={onCloseModal}
      width={768}
      footer={
        <Row gutter={[8, 8]} justify="start">
          <Col span={24} order={3} md={{ span: 8, order: 1 }}>
            <Button
              type="ghost"
              onClick={() => {
                onCloseModal();
              }}
              block
            >
              {t('pageCart:modal-select-pending-orders-button-cancel-text')}
            </Button>
          </Col>
          <Col span={24} order={2} md={{ span: 8, order: 2 }}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                onSeperateOrder();
              }}
              block
            >
              {t('pageCart:modal-select-pending-orders-button-create-new-text')}
            </Button>
          </Col>
          <Col span={24} order={1} md={{ span: 8, order: 3 }}>
            <Button
              type="primary"
              onClick={() => {
                const [orderNumber, accessCode] = combineOrderStr.split(':');
                onCombineOrder(orderNumber, accessCode);
              }}
              block
            >
              {t('pageCart:modal-select-pending-orders-button-combine-text')}
            </Button>
          </Col>
        </Row>
      }
    >
      <p>{t('pageCart:modal-select-pending-orders-desc', { orderCount: pendingOrders.length })}</p>
      {pendingOrders.length > 0 && (
        <Selection
          onChange={value => {
            setCombineOrderStr(value);
          }}
          value={combineOrderStr}
          options={pendingOrders.map(pendingOrder => {
            return {
              value: formatOrderToCombineOrderStr(pendingOrder),
              label: pendingOrder.orderNumber
            };
          })}
        />
      )}
    </Modal>
  );
};

const Cart = ({ propsContextCart }) => {
  const {
    checkoutCart,
    productItems,
    updateProductItemsValidity,
    updateProductItemQuantity,
    isLoading: isLoadingCart,
    customer,
    updateCacheCustomer,
    sourceOrder
  } = propsContextCart;

  const { t } = useTranslation(['pageCart']);
  const [form] = useForm();
  const nav = useNav();

  const [isFirstTimeValidate, setIsFirstTimeValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const {
    hasInitiateCheckout,
    isLoading: isLoadingCheckout,
    shouldAskForCombineOrder,
    customerPendingOrders,

    handleOnCloseModal,
    handleOnClickProceedToCheckout,
    handleOnSelectCombineOrder,
    handleOnSelectSeperateOrder
  } = useCheckout({
    t,
    form,
    updateProductItemsValidity,
    sourceOrder,
    checkoutCart,
    updateCacheCustomer
  });

  useScrollToTop();

  useEffect(() => {
    if (!isFirstTimeValidate) {
      setIsFirstTimeValidate(true);
      updateProductItemsValidity().catch(() => {
        const fieldsToValidate = productItems.map(productItem => [`${productItem.id}`, 'quantity']);
        form.validateFields(fieldsToValidate);
      });
    }
  }, [isFirstTimeValidate, form, updateProductItemsValidity, productItems]);

  useEffect(() => {
    setTotal(
      productItems.reduce((acc, cur) => {
        const t = Number(cur.quantity) * Number(cur.priceAmount);
        return (acc += t);
      }, 0)
    );
  }, [productItems]);

  const cartFormInitialValue = useMemo(() => {
    if (!isLoadingCart) {
      let formattedData = { ...(customer && { name: customer.name, contact: customer.contact, email: customer.email }) };
      productItems.forEach(d => (formattedData[`${d.id}`] = { quantity: d.quantity }));
      return formattedData;
    }
    return undefined;
  }, [isLoadingCart, productItems, customer]);

  const handleOnClickContinueShopping = () => nav(homeRoute.path);

  const handleOnFailProceedToCheckout = validationInfo => {
    const { errorFields } = validationInfo;
    if (errorFields.length > 0) {
      errorFields && errorFields.forEach(field => message.error(field.errors[0]));
    }
  };

  const handleOnClickRemoveItem = productItemIndex => {
    setIsLoading(true);
    propsContextCart
      .removeProductItem(productItemIndex)
      .then(() => {
        message.success(t('pageCart:success-remove-cart'));
        setIsLoading(false);
      })
      .catch(ex => {
        setIsLoading(false);
        message.error(ex.message);
      });
  };

  const handleOnChangeQuantity = productItemIndex =>
    debounce(async (newQuantity, quantityLimit) => {
      const isAllowToSaveQuantity =
        productItems[productItemIndex].error ||
        (!!Number(newQuantity) &&
          Number(newQuantity) > 0 &&
          Number(newQuantity) <= quantityLimit &&
          productItems[productItemIndex].quantity !== newQuantity);

      if (isAllowToSaveQuantity) {
        setIsLoading(true);
        updateProductItemQuantity(productItemIndex, Number(newQuantity))
          .then(() => {
            message.success(t('pageCart:success-update-cart'));
            setIsLoading(false);
          })
          .catch(ex => {
            setIsLoading(false);
            message.error(ex.message);
          });
      }
    }, 275);

  return (
    <>
      <Helmet>
        <title>Your Cart</title>
      </Helmet>
      <Form
        form={form}
        initialValues={cartFormInitialValue}
        style={{ width: '100%', marginBottom: '40px', padding: '24px 0' }}
        onFinish={handleOnClickProceedToCheckout}
        onFinishFailed={handleOnFailProceedToCheckout}
      >
        <h1 style={{ margin: 0 }}>{t('pageCart:title')}</h1>
        {productItems.length === 0 ? (
          <ContentContainer>
            <div style={{ padding: '32px 0' }}>
              <Empty
                image={EmptyCartImagePlaceholder}
                imageStyle={{
                  height: 60
                }}
                description={
                  <span>
                    {t('pageCart:empty-desc-1')}{' '}
                    <Button type="link" style={{ padding: 0 }} onClick={handleOnClickContinueShopping}>
                      {t('pageCart:empty-desc-2')}
                    </Button>
                  </span>
                }
              >
                <Button type="primary" icon={<DoubleRightOutlined />} onClick={handleOnClickContinueShopping}>
                  {t('pageCart:continue-shopping')}
                </Button>
              </Empty>
            </div>
          </ContentContainer>
        ) : (
          <>
            <Spin spinning={isLoading || isLoadingCheckout}>
              <ContentContainer>
                {sourceOrder && (
                  <Alert
                    showIcon
                    message={
                      <span>
                        {t('pageCart:notification-banner-combine-order-msg')} <b>{sourceOrder.orderNumber}</b>
                      </span>
                    }
                  />
                )}
                <List
                  itemLayout="horizontal"
                  dataSource={productItems}
                  renderItem={(productItem, index) => {
                    const productThumbnailPhotoUrl = productItem.coverPhoto?.thumbnail?.url || productItem.coverPhotoUrl;
                    const productPreviewPhotoUrl = productItem.coverPhoto?.original?.url || productItem.coverPhotoUrl;
                    return (
                      <ListItem key={productItem.id}>
                        <ListItemMeta
                          description={
                            <div style={{ width: '100%' }}>
                              <Row gutter={32} style={{ margin: 0 }}>
                                <PhotoCol span={24} md={6} xl={4}>
                                  <StyledImagesPreview
                                    thumbnailImageUrl={productThumbnailPhotoUrl || ImagePlaceholder}
                                    previewImageUrl={productPreviewPhotoUrl || ImagePlaceholder}
                                  />
                                </PhotoCol>
                                <Col span={24} md={18} xl={20}>
                                  <ProductInfo t={t} label={productItem.label} desc={productItem.description} />
                                  <CartProductItem
                                    form={form}
                                    formInputName={[`${productItem.id}`, 'quantity']}
                                    priceAmt={productItem.priceAmount}
                                    oriPrice={productItem.oriPrice}
                                    limitQty={generateQtyLimit(productItem.inventory, productItem.purchaseLimit)}
                                    onUpdateQuantity={handleOnChangeQuantity(index)}
                                    onRemoveItem={() => handleOnClickRemoveItem(index)}
                                    hasStock={productItem.inventory > 0}
                                    hasFeedback={!!productItem.error}
                                    feedbackMessage={productItem.error && productItem.error.message}
                                  />
                                </Col>
                              </Row>
                            </div>
                          }
                        />
                      </ListItem>
                    );
                  }}
                />
                <ContentSummaryInfoContainer>
                  <Descriptions bordered={false} column={1}>
                    <Descriptions.Item label={t('pageCart:cart-info-total-amount')} labelStyle={{ alignItems: 'center' }}>
                      <h3 style={{ margin: 0 }}>
                        <PriceDisplay amount={total} />
                      </h3>
                    </Descriptions.Item>
                  </Descriptions>
                  <Button type="link" icon={<DoubleRightOutlined />} style={{ padding: 0 }} onClick={handleOnClickContinueShopping}>
                    {t('pageCart:continue-shopping')}
                  </Button>
                </ContentSummaryInfoContainer>
              </ContentContainer>
            </Spin>
            <Divider />
            <ContentBasicInfoContainer>
              <h2>{t('pageCart:form-title-buyer-info')}</h2>
              <FormInput
                name="name"
                label={t('pageCart:form-field-name')}
                placeholder="e.g.: Joe Doe"
                requiredErrorMessage={t('pageCart:form-field-error-name')}
              />
              <FormContact
                name="contact"
                label={t('pageCart:form-field-contact')}
                options={[
                  { value: '+60', label: '+60' },
                  { value: '+65', label: '+65' }
                ]}
                requiredErrorMessage={t('pageCart:form-field-error-contact')}
              />
              <FormInput
                name="email"
                type="email"
                label={t('pageCart:form-field-email')}
                placeholder="e.g.: joedoe@gmail.com"
                requiredErrorMessage={t('pageCart:form-field-error-email')}
                isEmail
              />
              <Button
                loading={hasInitiateCheckout || isLoadingCheckout}
                type="primary"
                ghost
                block
                icon={<CheckOutlined />}
                htmlType="submit"
                disabled={productItems.length === 0}
              >
                {t('pageCart:form-submit-button')}
              </Button>
            </ContentBasicInfoContainer>
          </>
        )}
      </Form>
      <ModalSelectPendingOrders
        t={t}
        isShowModal={shouldAskForCombineOrder}
        pendingOrders={customerPendingOrders}
        onCloseModal={handleOnCloseModal}
        onCombineOrder={handleOnSelectCombineOrder}
        onSeperateOrder={handleOnSelectSeperateOrder}
      />
    </>
  );
};

export default withContextCart(Cart);
