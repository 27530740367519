import { Input as AntdInput } from 'antd';
import PropTypes from 'prop-types';

import { guard } from 'utils/general';

import { MaxLengthDisplay } from './Input.styles';

const InputComponent = ({ type, size, minRows, maxRows, ...props }) => {
  if (type === 'textarea') {
    return <AntdInput.TextArea autoSize={{ minRows, maxRows }} {...props} />;
  } else if (['password', 'newPassword'].includes(type)) {
    const autoComplete = type === 'password' ? 'password' : 'new-password';
    return <AntdInput.Password {...props} autoComplete={autoComplete} />;
  } else {
    return <AntdInput type={type} size={size} {...props} />;
  }
};

const Input = ({
  className,
  value,

  type,
  placeholder,
  prefix,
  suffix,
  minRows = 4,
  maxRows,
  maxLength,
  size = 'middle',

  isUpperCase = false,
  isLowerCase = false,
  isDisabled = false,

  onChange = () => {},
  onPressEnter,
  updateFormValue = () => {},

  extraProps = {}
}) => {
  const handleOnChange = e => {
    const inputValue = e.target.value;
    let formattedValue = inputValue;

    if (isUpperCase) {
      formattedValue = guard(() => inputValue.toUpperCase(), inputValue);
    } else if (isLowerCase) {
      formattedValue = guard(() => inputValue.toLowerCase(), inputValue);
    }

    onChange(formattedValue);
    updateFormValue(formattedValue);
  };

  return (
    <>
      {!isNaN(maxLength) && (
        <MaxLengthDisplay>
          Max {maxLength} letters, {maxLength - String(value || '').length} letters left
        </MaxLengthDisplay>
      )}
      <InputComponent
        className={className}
        value={value}
        type={type}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        minRows={minRows}
        maxRows={maxRows}
        maxLength={maxLength}
        size={size}
        disabled={isDisabled}
        onChange={handleOnChange}
        onPressEnter={onPressEnter}
        {...extraProps}
      />
    </>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  type: PropTypes.string,
  placeholder: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  size: PropTypes.string,

  isUpperCase: PropTypes.bool,
  isLowerCase: PropTypes.bool,
  isDisabled: PropTypes.bool,

  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  updateFormValue: PropTypes.func,

  extraProps: PropTypes.object
};

export default Input;
